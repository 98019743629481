<template>
    <div class="box">
        <h2>PDF Formular wählen</h2>
        <div class="body">
            <div class="form-group form-float form-select no-error">
                <select v-model="selProduct" id="selProduct" name="selProduct" placeholder=" ">
                    <option value=""></option>
                    <optgroup v-for="group in productGroups" v-bind:key="group.id" :label="group.label">
                        <option v-for="entry in group.products" v-bind:key="entry.id" v-bind:value="entry.id">
                            {{ entry.label }}
                        </option>
                    </optgroup>
                </select>
                <label for="selProduct">Produkt wählen</label>
            </div>
            <table>
                <tbody>
                    <tr class="clickable-row" v-for="form in pdfForms" v-bind:key="form.id">
                        <td @click="load(form.id)">{{ form.label }}</td>
                        <td style="width: 2rem">
                            <dropdown class="right">
                                <template slot="trigger"
                                    ><button class="fab btn flat"><i class="fal fa-ellipsis-v"></i></button
                                ></template>
                                <template slot="menu">
                                    <ul>
                                        <li>
                                            <router-link :to="{ name: 'docs_pdfform_edit', params: { product: selProduct, id: form.id } }"><i class="fal fa-pencil"></i> Edit</router-link>
                                        </li>
                                        <li>
                                            <a href="#" @click.prevent="deleteForm(form.id)"><i class="fal fa-trash-alt"></i> Delete</a>
                                        </li>
                                    </ul>
                                </template>
                            </dropdown>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div v-show="pdfForms.length == 0 && selProduct != ''" class="info-box">
                Es sind noch keine PDF Formulare vorhanden
            </div>
        </div>

        <button v-show="selProduct !== ''" @click="create()" class="fab size-l br secondary"><i class="fal fa-plus"></i></button>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selProduct: "",
            pdfForms: [],
            productGroups: []
        };
    },
    created() {
        this.fetchData();
    },
    methods: {
        load(id) {
            this.$router.push({ name: "docs_pdfform_edit", params: { product: this.selProduct, id: id } });
        },
        create() {
            this.$router.push({ name: "docs_pdfform_create", params: { product: this.selProduct } });
        },
        deleteForm(id) {
            this.$snotify.confirm("PDF Formular dauerhaft löschen?", "Letzte Möglichkeit", {
                closeOnClick: false,
                position: "centerCenter",
                buttons: [
                    {
                        text: "Ja",
                        action: toast => {
                            this.performDelete(id);
                            this.$snotify.remove(toast.id);
                        }
                    },
                    {
                        text: "Nein"
                    }
                ]
            });
        },
        performDelete(id) {
            this.$api.delete("/docs/category/" + this.selProduct + "/pdfform/" + id).then(
                () => {
                    // Event deleted
                    this.$snotify.success("Pdf Form gelöscht");
                    this.fetchData();
                },
                () => {
                    // Delete failed
                    this.$snotify.error("Löschen fehlgeschlagen");
                }
            );
        },
        fetchData() {
            this.fetchProductGroups();
        },
        fetchProductGroups() {
            this.$api.get("/docs/products").then(
                response => {
                    this.productGroups = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der Produkte");
                }
            );
        },
        fetchPdfForms() {
            this.$api.get("/docs/category/" + this.selProduct + "/pdfforms").then(
                response => {
                    this.pdfForms = response.data.data;
                },
                () => {
                    this.$snotify.error("Fehler beim Laden der PDF Formulare");
                }
            );
        }
    },
    watch: {
        selProduct: function() {
            this.fetchPdfForms();
        }
    }
};
</script>

<style lang="scss" scoped>
td.remove {
    width: 2rem;
}

div.info-box {
    margin-top: 1.5rem;
}
</style>
